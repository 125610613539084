<template>
  <div v-if="!product.isArchived" class="b2b-buying-zone__price flex flex-wrap items-start heading--md font-medium">
    <comparable-value-drawer
      :is-drawer-open="isComparableValueDrawerOpen"
      @close="setIsComparableValueDrawerOpen(false)"
    />
    <span v-if="price" id="priceRange">
      <input id="product-display-price" type="hidden" :value="displayPrice" />
      <span qaid="actual-price" class="actual-price" :class="{salePrice: price.displayPrice.isSale}">{{
        displayPrice
      }}</span>
      <span v-if="isB2CWithoutOldPrice" class="inline-flex items-center">
        <span qaid="retail-msrp-text" class="text-xs text-gray-60 font-normal">
          {{ $t('text.msrp') }}
        </span>
        <pdl-button
          v-if="isValidLangForComparableValue"
          tertiary
          :icon="'info_outline'"
          small
          class="comparable-value-tooltip"
          :icon-only="true"
          @click="setIsComparableValueDrawerOpen(true)"
        />
      </span>
      <span class="sr-only">{{ $t('text.buyingZone.salePrice') }}</span>
      <span v-if="price.excludeTaxes && isConsumerFriendlyMode" id="excludeTaxText" class="exclude-tax">
        {{ $t('product.price.excludeTax') }}
      </span>
    </span>

    <span v-if="price && price.displayPrice.oldPrice">
      <span qaid="original-price" class="text-gray-60 line-through ml-1">{{ price.displayPrice.oldPrice }}</span>
      <span class="inline-flex items-center">
        <span v-if="!b2b" class="text-xs text-gray-60 font-normal" qaid="retail-msrp-text">{{ $t('text.msrp') }}</span>
        <span class="sr-only">{{ $t('text.buyingZone.originalPrice') }}</span>
        <pdl-button
          v-if="isValidLangForComparableValue"
          tertiary
          :icon="'info_outline'"
          small
          class="comparable-value-tooltip"
          :icon-only="true"
          @click="setIsComparableValueDrawerOpen(true)"
        />
      </span>
    </span>
    <span v-if="b2b" class="ml-1 text-sm">
      <span v-if="isConsumerFriendlyMode" :qaid="qaidValueRetail">{{ $t('text.suggestedRetail') }}</span>
      <span v-else :qaid="qaidValueRetail">{{ $t('text.retailerCost') }}</span>
    </span>

    <pdp-product-price-tooltip
      v-if="b2b"
      v-show="!isConsumerFriendlyMode"
      :price="price"
      @min-qty-change="updateMinQty"
    >
    </pdp-product-price-tooltip>
    <template v-if="isRestrictedToRetailer">
      <pdl-label v-if="trekUCompetencyLevel" qaid="pdp-treku-competency-label" class="pl-5 ml-1" kind="positive">{{
        trekUCompetencyLevelTruncated
      }}</pdl-label>
      <pdl-label v-else class="pl-5 ml-1" qaid="pdp-treku-no-competency-label" kind="important">{{
        $t('text.buyingZone.B2B.trekU.noDiscount')
      }}</pdl-label>
    </template>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import get from 'lodash/get';
import PdpProductPriceTooltip from '@/components/containers/pdp/b2b/PdpProductPriceTooltip';
import {PdlLabel} from '@pedal/pdl-label';
import {PdlButton} from '@pedal/pdl-button';
import ComparableValueDrawer from '@/components/drawer/pricing/ComparableValueDrawer.vue';

export default {
  name: 'PdpProductPrice',
  components: {PdpProductPriceTooltip, PdlLabel, PdlButton, ComparableValueDrawer},
  props: {
    product: {
      type: Object,
      required: true,
    },
    retailer: {
      type: Object,
      default: () => null,
    },
    consumer: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isComparableValueDrawerOpen: false,
    };
  },
  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode', 'b2b', 'language']),
    ...mapState('user', ['trekUCompetencyLevel']),
    ...mapGetters('advancedOrdering', ['advancedOrderingMode']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    displayPrice() {
      if (this.isConsumerFriendlyMode && this.price.displayPrice.applePrice) {
        return this.price.displayPrice.applePrice;
      }
      return get(this.price.displayPrice, this.advancedOrderingMode && this.b2b ? 'advancedValue' : 'value', '');
    },
    price() {
      if (!this.b2b) {
        return this.consumer;
      }
      return this.isConsumerFriendlyMode ? this.consumer : this.retailer;
    },
    qaidValueRetail() {
      return !this.isConsumerFriendlyMode ? 'retail.cost.text' : 'retail.suggested.text';
    },
    trekUCompetencyLevelTruncated() {
      return this.trekUCompetencyLevel?.split(' ')[0];
    },
    isB2CWithoutOldPrice() {
      return !this.b2b && this.price && !this.price.displayPrice.oldPrice;
    },
    isValidLangForComparableValue() {
      return ['en_US', 'en_CA', 'fr_CA'].includes(this.language);
    },
  },
  methods: {
    updateMinQty(quantity) {
      this.$emit('min-qty-change', quantity);
    },
    setIsComparableValueDrawerOpen(value = false) {
      this.isComparableValueDrawerOpen = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.comparable-value-tooltip {
  color: var(--gray-60);
  padding: 0.25rem 0.25rem 0.5rem;

  &:hover {
    color: var(--gray-60);
    background-color: var(--white);
  }
}
</style>
