<template>
  <div v-if="productPowerReviewsId">
    <div :id="`pr-reviewsnippet-${productPowerReviewsId}`" class="pr-reviewsnippet pr-reviewsnippet--min-height">
      <div data-pr-placeholder />
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import {delayedScrollToElement} from '@/utils/scroll-to';

export default {
  name: 'ProductReviewsSummary',
  props: {
    productPowerReviewsId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isSummaryComponentRendered: false,
      stickyNavOffset: 150,
    };
  },
  computed: {
    ...mapState('powerReviews', ['productDetails', 'isWindowPwrLoaded', 'prBaseProperties']),
    ...mapState('viewSize', ['viewBreakpoint']),
    ...mapGetters('viewSize', ['viewBreakpointLessThan']),
  },
  watch: {
    isWindowPwrLoaded(val) {
      if (val) {
        this.initPowerReviews();
      }
    },
  },
  mounted() {
    if (this.isWindowPwrLoaded) {
      this.initPowerReviews();
    }
  },
  methods: {
    ...mapMutations('pdp', ['setActiveTab']),
    initPowerReviews() {
      if (window.pwr && !this.isSummaryComponentRendered) {
        let reviews = [];

        let powerReview = {
          api_key: this.productDetails.pr_api_key,
          locale: this.productDetails.pr_locale,
          merchant_group_id: this.productDetails.pr_merchant_group_id,
          merchant_id: this.productDetails.pr_merchant_id,
          page_id: this.productPowerReviewsId,
          review_wrapper_url: this.productDetails.pr_write_review,
          on_render: (config, data) => {
            let reviewsCountElement = document.querySelector('.pr-snippet-review-count');
            let reviewsStarsElement = document.querySelector('.pr-rating-stars');
            let reviewsStarsContainer = document.querySelector('.pr-snippet-stars');
            let writeReviewElement = document.querySelector('.pr-snippet-write-review-link');
            let separatorSpan = document.createElement('span');
            separatorSpan.classList.add('pr-separator');
            separatorSpan.innerText = ' / ';
            reviewsStarsContainer.setAttribute('tabIndex', 0);
            let scrollToReviews = () => {
              //search for any tab that contains the id 'ReviewTab' - there should only be one per PDP.
              const reviewTabElementName = document.querySelector('span[id*="ReviewsTab"]')?.id;
              if (!reviewTabElementName) return;
              this.setActiveTab(reviewTabElementName);
              const reviewHeaderElement = document.getElementById('MyReviewHeader');
              if (this.viewBreakpointLessThan('large')) {
                delayedScrollToElement(reviewHeaderElement, this.stickyNavOffset, 400);
              } else {
                delayedScrollToElement(reviewHeaderElement, this.stickyNavOffset, 50);
              }
            };
            reviewsStarsElement.addEventListener('click', scrollToReviews);
            if (data.review_count) {
              reviewsCountElement.innerText = `${data.review_count} ${this.prBaseProperties.pRHeaderText}`;
              reviewsCountElement.setAttribute('tabIndex', 0);
              reviewsCountElement.setAttribute('role', 'button');
              reviewsCountElement.after(separatorSpan);
              reviewsCountElement.addEventListener('click', scrollToReviews);
              reviewsCountElement.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                  scrollToReviews();
                }
              });
            } else {
              reviewsCountElement.innerText = '';
              writeReviewElement.innerText = this.prBaseProperties.pRNoReviews;
            }
          },
          components: {
            ReviewSnippet: `pr-reviewsnippet-${this.productPowerReviewsId}`,
          },
          product: {
            name: this.productDetails.pr_product_name,
            url: this.productDetails.pr_product_url,
            image_url: this.productDetails.pr_product_image,
            description: this.productDetails.pr_product_description,
            category_name: this.productDetails.pr_product_category_name,
            manufacturer_id: this.productDetails.pr_product_manufacturer_id,
            upc: this.productDetails.pr_product_upc,
            brand_name: this.productDetails.pr_product_brand_name,
            price: this.productDetails.pr_product_price,
            in_stock: this.productDetails.pr_product_in_stock,
          },
        };

        reviews.push(powerReview);
        if (reviews && reviews.length) {
          window.pwr('render', reviews);
          this.isSummaryComponentRendered = true;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
// setting a min height on the Power Reviews Snippet element so that the content doesn't shift on page load
// This plus the placeholder div are the recommendations to avoid content shift in the Power Reviews docs
// https://help.powerreviews.com/Content/Resources-PWR/Google-SEO-Updates-Page-Experience.htm?mkt_tok=NjUzLURNWi0xMDYAAAF9BlqXtjUDNpLPGsro37_Y8jCs_Q6lX8HJp_ZgUUHIz3N0fLkMBYESBVtuomw6OU73n7-NCwTseXm-stgtrVMU-I-0Dm1agY9unAkVKnW7SIE#Reduce
.pr-reviewsnippet--min-height {
  min-height: 22px;
}
</style>
